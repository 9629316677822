.section__padding {
    padding: 1rem 2rem;
  }
.section__paddings {
    padding: 2rem 3rem;
  }
.section__paddingss {
    padding: 2rem 3rem;
  }
  @media screen and (max-width: 500px) {
    .section__paddingss {
      padding: 2rem 1rem;
    }
  }
  
  .flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .app__bg {
    /* background: url('./assets/bg.png'); */
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
  }
  
  .app__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
  }
  .app__wrappers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 70vh; */
  }
  .app__wrapped {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 70vh; */
  }
  
  .app__wrapper_info {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .app__wrapper_img {
    flex: 1;
    width: 100%;
    height: 75vh;
    object-fit: cover;
    /* display: flex;
    justify-content: center;
    align-items: center; */

  
    margin-left: 2rem;
  }
  .app__wrappers_img {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    margin-left: 2rem;
  }
  
  .app__wrapper_img-reverse {
    justify-content: flex-start;
    margin-right: 2rem;
  }
  
  .app__wrapper_img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  .app__wrappers_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .custom__button {
    background-color: var(--color-black);
      color: var(--color-crimson);
      font-family: var(--font-base);
      font-weight: 700;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      line-height: 28px;
      font-size: 16px;
      padding: 0.5rem 1.5rem;
      border-radius: 1px;
      border: none;
      outline: none;
      cursor: pointer;
  }
  .custom__buttons {
    /* background-color: var(--color-crimson) !important; */
      color: var(--color-black);
      font-family: var(--font-base);
      font-weight: 700;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      line-height: 28px;
      font-size: 16px;
      padding: 0.5rem 1.5rem;
      border-radius: 1px;
      border: none;
      outline: none;
      cursor: pointer;
  }
  .custom__buttonsd {
    /* background-color: var(--color-black) !important; */
      color: var(--color-black);
      font-family: var(--font-base);
      font-weight: 700;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      line-height: 28px;
      font-size: 16px;
      padding: 0.5rem 1.5rem;
      border-radius: 1px;
      border: 2px solid black;
      outline: none;
  }
  .custom__buttonss {
    background-color: var(--color-crimson) !important;
      color: var(--color-black);
      font-family: var(--font-base);
      font-weight: 700;
      text-transform: capitalize;
      font-size: 16px;
      padding: 0.5rem 1.5rem;
      border-radius: 1px;
      border: none;
      outline: none;
      cursor: pointer;
  }
  .custom__buttoned {
    background-color: var(--color-black);
      color: var(--color-crimson);
      font-family: var(--font-base);
      font-weight: 700;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      line-height: 28px;
      font-size: 16px;
      padding: 0.5rem 1.5rem;
      border-radius: 1px;
      border: none;
      outline: none;
      cursor: pointer;
  }
  
  .p__cormorant {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 29.9px;
    font-size: 23px;
  }
  
  .p__opensans {
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 400;
    text-transform: capitalize;
    font-size: 16px;
  }
  .p__opensanss {
    font-family: var(--font-alt);
    color: var(--color-black);
    font-weight: 400;
    text-transform: capitalize;
    font-size: 16px;
  }
  .p__opensansss {
    font-family: var(--font-alt);
    color: var(--color-black);
    font-weight: 200;

    font-size: 13px;
  }
  .p__opensand {
    font-family: var(--font-alt);
    color: var(--color-black);
    font-weight: 400;
    font-size: 16px;
  }
  
  .headtext__cormorant {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 64px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
  }
  
  .spoon__img {
    width: 45px;
  }
  
  @media screen and (min-width: 2000px) {
    .custom__button,
    .p__cormorant {
      font-size: 37px;
      line-height: 67px;
    }
  
    .p__opensans {
      font-size: 30px;
    }
  
    .headtext__cormorant {
      font-size: 150px;
      line-height: 210px;
    }
  
    .spoon__img {
      width: 80px;
    }
  }
  
  @media screen and (max-width: 1150px) {
    .app__wrapper {
      flex-direction: column;
    }
    .app__wrappers {
      flex-direction: column;
      height: auto;
    }
  
    .app__wrapper_img {
      margin: 1rem 0 0 0;
    }
  
    .app__wrapper_img-reverse {
      margin: 0 0 3rem 0;
    }
  
    .app__wrapper_img img {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 850px) {
    .section__padding {
      padding: 2rem;
    }
  }
  
  @media screen and (max-width: 650px) {
    .section__padding {
      padding: 1.5rem ;
    }
    
    .p__cormorant {
      font-size: 21px;
    }
  
    .p__opensans {
      font-size: 14px;
    }
    .app__wrappers_img {
        margin-left: 0;
        margin-top: 1rem;
      }
  }
  
  @media screen and (max-width: 450px) {
    .p__opensans {
      font-size: 12px;
    }
  
    .p__cormorant {
      font-size: 19px;
    }
  
    .headtext__cormorant {
      font-size: 45px;
      line-height: 70px;
    }
  }
  