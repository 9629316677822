h1,h2,h3,h4,h5,h6,p{
    margin: 0;
    padding: 0;
}


.app__navbar{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-white);
    padding: 1rem 2rem;
    border-bottom: 1px solid  var(--color-crimson);
}




@media screen and (max-width: 2000px) {

}
@media screen and (max-width: 1150px) {

}
@media screen and (max-width: 650px) {
    .app__navbar{
        padding: 1rem;
    }
}
















.app__header{
    background-color: var(--color-black);
    margin: 0;
}
.app__header-h1{
    font-family: var(--font-base);
    color: var(--color-black);
    text-transform: uppercase;
    font-size: 20px; 
    flex: 1;
} 
.app__header-h1e{
    font-family: var(--font-base);
    color: var(--color-black);
    text-transform: uppercase;
    font-size: 20px; 
} 
.app__header-h1s{
    font-family: var(--font-alt);
    color: var(--color-black);
    text-transform: uppercase;
    font-size: 30px; 
    flex: 1;
} 
.app__header-h1d{
    font-family: var(--font-alt);
    color: var(--color-black);
    text-transform: uppercase;
    font-size: 30px; 
} 
.hij{
    background-color: var(--color-white) !important;
}
.email{
    color: var(--color-crimson);
    font-size: 15px;
    padding: 4px 7px;
    background-color: var(--color-black);
    border-radius: 3px;
}
.contained__headings{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

@media screen and (max-width: 650px){
    .app__header-h1{
        text-transform: uppercase;
    }
}







.rowe{
    display: flex;
    width: 90%;
    margin: 15px auto;
    flex-wrap: wrap;
}
@media  screen and (max-width:1050px) {
    .rowe{
        width: 100%;
    }
}
@media  screen and (max-width:1040px) {
    .rowe{
        width: 80%;
        flex-direction: column;
    }
}
@media  screen and (max-width:500px) {
    .rowe{
        width: 100%;
    }
    .rowe-child{
        padding: 2rem 1rem;
    }
    .rowe-item h3{
        font-size: 15px;
    }
}
.rowe-child{
    justify-content: center;
    align-items: center;
    text-align: justify;
    margin: 5px 10px;
    padding: 3rem 2rem;
    margin-top: 20px;
    border-radius: 10px;
    border: 2px solid var(--color-golden);
}
.rowe-child .rowe-item{
    align-items: center;
    /* border: 2px solid blue; */
}
.rowe-item .grave-name{
    font-size: 25px;
    /* background-color: var(--color-black); */
    color: var(--color-crimson);
    margin-bottom: 15px;
}
.rowe-item h3{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
.rowe-item h6{
    margin-bottom: 10px;
    font-weight: 600;
}



h6.edit{
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    font-weight: 500;
    margin-bottom: -10px;
}
h6.nein{
    font-size: 14px;
}
h6.kies{
    margin-top: 15px;
}
h6.kie{
    margin-top: 15px;
    margin-bottom: 5px;
}






























.app__gallery{
    display: flex;
    flex-direction: row;
    /* background: url('../assets/grass.jpg'); */
    padding: 4rem 0 4rem 6rem;
}
.app__gallery-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    min-width: 500px;
    padding-right: 2rem;
}
.app__gallery-content button{
    margin-top: 1rem;
}
.app__gallery-images{
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;
}
.app__gallery-images_container{
    display: flex;
    flex-direction: row;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.app__gallery-images_container::-webkit-scrollbar{
    display: none;
}
.app__gallery-images_card{
    position: relative;
    min-width: 301px;
    height: 420px;
    margin-right: 2rem;
}
.gallery__image-icon{
    position: absolute;
    color: #fff;
    font-size: 2rem;
    opacity: 0.5;
    transition: 0.5s ease;
    cursor: pointer;
}
.app__gallery-images_card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: 0.5s ease;
}
.app__gallery-images_card:hover img{
    opacity: 0.35;
}
.app__gallery-images_card:hover .gallery__image-icon{
    opacity: 1;
}
.app__gallery-images_arrows{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    position: absolute;
    bottom: 5%;
}
.gallery__arrow-icon{
    color: var(--color-black);
    font-size: 2rem;
    cursor: pointer;
    background: var(--color-white);
    border-radius: 5px;
}
.gallery__arrow-icon:hover{
    color: var(--color-white);
    background: var(--color-golden);
}

@media screen and (min-width: 2000px){
    .app__gallery-content{
        min-width: 1000px;
        padding-right: 4rem;
    }
    .app__gallery-images_card{
        min-width: 400px;
        height: 550px;
    }
}
@media screen and (max-width: 1150px){
    .app__gallery{
        flex-direction: column;
    }
    .app__gallery-images{
        max-width: 100%;
        margin: 5rem 0;
    }
}
@media screen and (max-width: 850px){
    .app__gallery{
        padding: 4rem 0 4rem 4rem;
    }
}
@media screen and (max-width: 650px){
    .app__gallery{
        padding: 4rem 0 4rem 2rem;
    }
    .app__gallery-content{
        min-width: 100%;
    }
    .app__gallery-images_card{
        min-width: 220px;
        height: 300px;
    }
}