.container{

    width: 80%;
    display: flex;
    align-items: center;
    margin: 30px auto;
    margin-top: 20vh;
    // background-color: var(--color-black);
    border: 2px solid var(--color-golden);
    justify-content: center;

    .app__footer-form {
        width: 80%;
        flex-direction: column;
        margin: 1rem 2rem;
        // border: 2px solid var(--color-golden);
        border-radius: 10px;
      
        div {
          width: 100%;
      
          margin: 0.75rem 0;
          border-radius: 10px;
          cursor: pointer;
          background-color: var(--color-crimson);
      
          transition: all 0.3s ease-in-out;
      
          input,
          textarea {
            width: 100%;
            padding: 0.95rem;
            border: none;
            border-radius: 7px;
            // background-color: var(--color-crimson);
      
            // font-family: var(--font-base);
            color: var(--color-black);
            outline: none;

            &::placeholder{
                color: var(--color-black);
                opacity: 0.5;
            }
          }
      
          textarea {
            height: 170px;
          }
      
          &:hover {
            box-shadow: 0 0 25px var(--primary-color);
          }
        }
      
        button {
          padding: 0.7rem 1rem;
          border-radius: 10px;
          border: none;
          background-color: var(--color-black);
      
          font-weight: 500;
          color: var(--color-crimson);
          outline: none;
          margin: 2rem 0 0 0;
        //   font-family: var(--font-base);
      
          transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
          cursor: pointer;
      
          &:hover {
            background-color: var(--color-crimson);
            color: var(--color-black);
          }
        }
      
        @media screen and (max-width: 768px) {
          width: 80%;
          margin: 1rem 0;
        }
      }

      @media screen and (max-width: 768px) {
        width: 80%;
      }
}